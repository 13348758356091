<template>
  <UBanner>
    <article class="box">
      <RouterLink
        style="text-decoration: none; color: inherit;"
        to="/library"
      >
        <h1 class="page-title">
          {{ librarySettings.title }}
        </h1>
      </RouterLink>
      <VCard class="library__card">
        <VBreadcrumbs
          :items="breadCrumbs"
          divider=">"
        />
        <VCol
          cols="12"
          lg="12"
        >
          <VTextField
            v-model="search"
            append-icon="search"
            :label="$t('search.one')"
            :placeholder="$t('$app.library_searching_by_author_title_year_keywords') + ':'"
            clearable
            hide-details
          />
        </VCol>
        <VContainer class="library__search-container">
          <VItem
            v-show="extendedSearch"
          >
            <VRow :class="{'library-row__extended' : extendedSearchLibrary}">
              <VCol
                cols="12"
                lg="12"
              >
                <VSelect
                  v-model="catalog"
                  :label="$t('$app.library_catalog')"
                  :items="selectedLibraryCatalogs"
                  item-text="name"
                  item-value="children"
                  persistent-hint
                  return-object
                  single-line
                />
              </VCol>
              <VCol
                cols="12"
                md="3"
                sm="6"
              >
                <VTextField
                  v-model="searchAuthor"
                  color="secondary"
                  append-icon="search"
                  :label="$t('$app.library_author')"
                  :placeholder="$t('$app.library_enter_the_author')"
                  clearable
                />
              </VCol>
              <VCol
                cols="12"
                md="3"
                sm="6"
              >
                <VTextField
                  v-model="searchYear"
                  color="secondary"
                  append-icon="search"
                  :label="$t('$app.library_publication_year')"
                  :placeholder="$t('$app.library_enter_the_publication_year')"
                  clearable
                />
              </VCol>
              <VCol
                cols="12"
                md="3"
                sm="6"
              >
                <VTextField
                  v-model="searchPublicationLocation"
                  color="secondary"
                  append-icon="search"
                  :label="$t('$app.library_place_of_publication')"
                  :placeholder="$t('$app.library_enter_the_place_of_publication')"
                  clearable
                />
              </VCol>
              <VCol
                cols="12"
                md="3"
                sm="6"
              >
                <VTextField
                  v-model="searchPublicationOffice"
                  color="secondary"
                  append-icon="search"
                  :label="$t('$app.library_publisher')"
                  :placeholder="$t('$app.library_enter_the_name_of_publisher')"
                  clearable
                />
              </VCol>
            </VRow>
          </VItem>
          <div class="library__buttons-group">
            <VBtn
              class="library__search"
              color="primary"
              @click="requestExtendedSearchLibrary"
            >
              {{ $t('search.one') }}
            </VBtn>
            <VBtn
              :outlined="libraryTreeViewShow"
              color="primary"
              class="library__search-catalog"
              @click="libraryTreeViewShow = !libraryTreeViewShow"
            >
              <VIcon> {{ libraryTreeViewShow ? 'arrow_drop_down' : 'arrow_drop_up' }} </VIcon>
              {{ $t('$app.library_catalog') }}
            </VBtn>
            <VBtn
              id="allBooks"
              class="library__search-extended"
              outlined
              @click="extendedSearch = !extendedSearch"
            >
              <VIcon> {{ !extendedSearch ? 'arrow_drop_down' : 'arrow_drop_up' }} </VIcon>
              {{ $t('$app.library_advanced_search') }}
            </VBtn>
          </div>
        </VContainer>
        <VRow class="library__row-container">
          <VCol
            :class="{ 'library__tree-view': libraryTreeViewShow }"
            lg="3"
            xs="12"
          >
            <LibraryTreeView :request-extended-search-library="requestExtendedSearchLibrary" />
          </VCol>
          <VCol
            lg="9"
            xs="12"
          >
            <LibraryAllBooks :extended-search-library="extendedSearchLibrary" />
            <VPagination
              v-show="paginationPager.total_pages > 1"
              v-model="page"
              class="library__pagination"
              :length="paginationPager.total_pages"
              :total-visible="7"
              @input="pageScroll"
            />
          </VCol>
        </VRow>
      </VCard>
    </article>
  </UBanner>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import UBanner from '@components/UBanner.vue'
import documentTitle from '@/mixins/documentTitle'
import LibraryTreeView from '@components/LibraryTreeView.vue'
import LibraryAllBooks from '@components/LibraryAllBooks.vue'

export default {
  name: 'TheLibrary',

  components: {
    LibraryAllBooks,
    LibraryTreeView,
    UBanner,
  },

  mixins: [documentTitle],

  data () {
    return {
      libraryTreeViewShow: true,
      libraryExtendedSearchView: true,
      searchAuthor: '',
      searchYear: '',
      searchPublicationLocation: '',
      searchPublicationOffice: '',
      extendedSearch: false,
      documentTitle: '$app.menuItem.library',
      search: '',
      catalog: '',
      page: 1,
      itemsPerPage: '',
      form: {
        year: {
          value: null,
        },
        catalog: {
          value: null,
          options: []
        },
      }
    }
  },

  computed: {
    ...mapGetters({
      library: getters.LIBRARY,
      librarySettings: getters.LIBRARY_SETTINGS,
      libraryCatalogs: getters.LIBRARY_CATALOGS,
      extendedSearchLibrary: getters.EXTENDED_SEARCH,
      paginationPager: getters.PAGINATION_PAGER
    }),

    selectedLibraryCatalogs () {
      const c = [{
        id: null,
        title: 'Каталог',
        name: this.$t('$app.library_any_catalog')
      }]
      const b = (list, parentId, level) => {
        list.filter(item => item.parent_id === parentId).forEach(item => {
          c.push({
            id: item.id,
            title: item.title,
            name: '_'.repeat(level) + item.title
          })
          b(list, item.id, level + 1)
        }
        )
      }
      b(this.$store.state.libraryCatalogs || [], 0, 0)
      return c
    },

    breadCrumbs () {
      return [
        {
          text: this.$store.getters.LIBRARY_SETTINGS.title,
          disabled: false,
          exact: true,
          to: '/library'
        },
        {
          text: this.$t('$app.library_catalog'),
          disabled: true,
        },
      ]
    }
  },

  watch: {
    page () {
      this.requestExtendedSearchLibrary().then(() => {
        if (window.bvi) {
          window.bvi._speechPlayer()
        }
      })
    }
  },

  created () {
    this.getLibrarySettings()
  },

  mounted () {
    this.requestExtendedSearchLibrary()
  },

  updated () {
    this.pageScroll()
  },

  methods: {
    ...mapActions({
      getLibrary: actions.GET_LIBRARY,
      getLibrarySettings: actions.GET_LIBRARY_SETTINGS,
      getLibraryCatalogs: actions.GET_LIBRARY_CATALOGS,
      getExtendedSearch: actions.GET_EXTENDED_SEARCH
    }),

    requestExtendedSearchLibrary () {
      const searchParams = {
        q: this.search ?? '',
        tags: this.$route.params.tag ?? '',
        catalog: this.catalog.id ?? '',
        author: ` ${this.searchAuthor}` ?? '',
        publication_year: this.searchYear ?? '',
        publication_location: this.searchPublicationLocation ?? '',
        publication_office: this.searchPublicationOffice ?? '',
        _page: this.page - 1,
        _items_per_page: 5,
      }
      const paramsString = new URLSearchParams(searchParams)
      const keysForDel = []
      paramsString.forEach((value, key) => {
        if (value === '') {
          keysForDel.push(key)
        }
      })

      keysForDel.forEach(key => {
        paramsString.delete(key)
      })

      return this.getExtendedSearch(paramsString)
    },

    pageScroll () {
      const scrollElement = document.querySelector('.library__search-extended')
      if (this.$vuetify.breakpoint.smAndDown) {
        scrollElement.scrollIntoView()
      } else {
        window.scrollTo(0, 0)
      }
    },
  }

}
</script>

<style lang="scss">

.v-breadcrumbs {
  li {
    max-width: 30%;
    word-break: break-word;
  }
  li:last-child {
    max-width: 50%;
    word-break: break-word;
  }
}

.library__pagination {
  padding: 20px;
}

.library__search-container {
  max-width: 100%;
  margin-left: 0;
}

.library__search-extended {
  margin-left: 1rem;
}

.library__search-catalog {
  display: none;
}
.library__buttons-group {
  display: inline;
}

.v-application {
  ul {
    padding: 18px 12px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .library__buttons-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .library__search {
    width: 32%;
  }
  .library__search-extended {
    width: 32%;
    margin-left: 0rem;
  }
  .library__search-catalog {
    display: inline;
    width: 32%;
  }
  .library__row-container .container {
    max-width: inherit;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .v-breadcrumbs {
    li {
      max-width: 100% !important;
      word-break: break-word;
    }
  }
  .library__buttons-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .library__tree-view {
    display: none;
  }
  .library__search {
    width: 32%;
  }
  .library__search-extended {
    width: 32%;
    margin-left: 0rem;
  }
  .library__search-catalog {
    display: inline;
    width: 32%;
  }
}

@media (max-width: 414px) {
  .library__search-extended {
    margin-top: 1rem;
    margin-left: 0px;
  }
  .library__search-catalog {
    display: inline;
    margin-top: 1rem;
  }
  .library__buttons-group {
    display: flex;
    justify-content: space-between;
  }
  .library__search {
    width: 100%;
  }
  .library__search-extended {
    width: auto;
    padding: 0 10px !important;
  }
  .library__search-catalog {
    padding: 0 10px !important;

  }
}

@media (max-width: 385px) {
  .library__search-extended {
    padding: 0 5px !important;
    width: 100%;
  }
  .library__search-catalog {
    padding: 0 5px !important;
    width: 100%;
  }
}

</style>
