<template>
  <UBanner>
    <article class="box">
      <RouterLink
        style="text-decoration: none; color: inherit;"
        to="/library"
      >
        <h1 class="page-title">
          {{ librarySettings.title }}
        </h1>
      </RouterLink>
      <VCard class="library__card">
        <VBreadcrumbs
          :items="breadCrumbs"
          divider=">"
        />
        <VCol
          cols="12"
          lg="12"
        >
          <VTextField
            v-model="search"
            append-icon="search"
            :label="$t('search.one')"
            :placeholder="$t('$app.library_searching_by_author_title_year_keywords') + ':'"
            clearable
            hide-details
          />
        </VCol>
        <VContainer class="library__search-container" />
        <VRow class="library__row-container">
          <VCol
            lg="3"
            xs="12"
          >
            <LibraryTreeView />
          </VCol>
          <VCol
            lg="9"
            xs="12"
          >
            <LibraryCurrentBooks :search="search" />
          </VCol>
        </VRow>
      </VCard>
    </article>
  </UBanner>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import UBanner from '@components/UBanner.vue'
import documentTitle from '@/mixins/documentTitle'
import LibraryTreeView from '@components/LibraryTreeView.vue'
import LibraryCurrentBooks from '@components/LibraryCurrentBooks.vue'

export default {
  name: 'TheLibrary',

  components: {
    LibraryCurrentBooks,
    LibraryTreeView,
    UBanner,
  },

  mixins: [documentTitle],

  data () {
    return {
      documentTitle: '$app.menuItem.library',
      search: '',
    }
  },

  computed: {
    ...mapGetters({
      library: getters.LIBRARY,
      librarySettings: getters.LIBRARY_SETTINGS,
      libraryCatalogs: getters.LIBRARY_CATALOGS,
      libraryCurrentCatalog: getters.LIBRARY_CURRENT_CATALOG,
    }),

    breadCrumbs () {
      return [
        {
          text: this.$store.getters.LIBRARY_SETTINGS.title,
          disabled: false,
          exact: true,
          to: '/library'
        },
        {
          text: this.$t('$app.library_catalog'),
          disabled: false,
          exact: true,
          to: '/library/documents'
        },
        {
          text: (this.libraryCatalogs || []).find(catalog => catalog.id === Number(this.$route.params.id))?.title ?? '',
          disabled: true,
        },
      ]
    },

  },

  created () {
    this.getLibrary()
    this.getLibrarySettings()
    this.getLibraryCatalogs()
  },

  methods: {
    ...mapActions({
      getLibrary: actions.GET_LIBRARY,
      getLibrarySettings: actions.GET_LIBRARY_SETTINGS,
      getLibraryCatalogs: actions.GET_LIBRARY_CATALOGS
    }),
  }
}
</script>

<style lang="scss">
.v-breadcrumbs {
  li {
    max-width: 30%;
    word-break: break-word;
  }
  li:last-child {
    max-width: 50%;
  }
}

.library__search-container {
  margin-left: 0;
}

.v-application {
  ul {
    padding: 18px 12px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .library__row-container .container {
    max-width: inherit;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .v-breadcrumbs {
    li {
      max-width: 100% !important;
      word-break: break-word;
    }
  }
}
</style>
